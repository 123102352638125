import * as React from "react";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import SEO from "../components/seo";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils";

const UnsubscribeThankyou = () => {
    return (
        <div className="wrapper wrapper-static">
            <SEO title="" />
            <Header />
            <section className="unsubscribe-section">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={8} lg={10} xs={11}>
                            <div className="page_404 text-center">
                                <h1>Unsubscribe Successful</h1>
                                <p>Thank you, we will now unsubscribe you from our mail list, if you need us in the future, please get in touch.</p>
                                <div className="d-flex flex-row justify-content-center">
                                    <Link to="/"><button className="btn btn-primary mx-3">Back to home page</button></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default UnsubscribeThankyou;
